import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import L from 'leaflet';

import LandingView from './Entrance/LandingView';
import Entrance from './Entrance';
import Session from './Session';
import './App.css';
import 'leaflet/dist/leaflet.css';
import 'react-quill/dist/quill.snow.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import 'rc-color-picker/assets/index.css';
import Language from './Entrance/Language';
import { $selectLanguage } from './Shared/state';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

const withStore = connect((state) => ({
  ready: state.Shared.ready,
  language: state.Shared.language,
  initialized: state.Shared.initialized,
  authenticated: state.Auth.authenticated,
}));

const propTypes = {
  ready: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

const Wrapper = (C) => withRouter(withStore(C));

class App extends Component {
  state = {

  };

choosLanguage= (lang) => {
  // console.log('language', lang);
  const { dispatch, language } = this.props;
  // this.setState({ lang1: lang });
  if (!language) {
    dispatch($selectLanguage(lang));
  }
}

render() {
  const {
    ready, initialized, authenticated, history, language,
  } = this.props;

  if (!ready) {
    return <LandingView />;
  }

  if (authenticated) {
    if (!language) {
      return <Language choosLanguage={this.choosLanguage} history={history} />;
    }
  } else {
    return <Entrance />;
  }

  if (!initialized) {
    return <LandingView />;
  }
  return language && authenticated ? <Session /> : <Entrance />;
}
}

App.propTypes = propTypes;

export default Wrapper(App);
