import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import {
  Layout, Menu, Icon, Dropdown, Button, Col, Row, Tag, Select, Tooltip,
} from 'antd';

import Home from '../Home';
import UserRouter from '../Users';
import TeamRouter from '../Team';
import ProfileView from '../Users/ProfileView';
import SitesRouter from '../Sites';
import AttractionsRouter from '../Attractions';
import ToursRouter from '../Tours';
import UserAvatar from '../components/UserAvatar';
import { $logout } from '../Auth/state';
import { $setSiteAsContext, $selectLanguage } from '../Shared/state';
import LOGO from '../assets/logo.png';

const withStore = connect((state) => ({
  ready: state.Activity.ready,
  authenticated: state.Auth.authenticated,
  user: state.Auth.user,
  mySites: state.Auth.user.group === 'admin'
    ? state.Sites.list
    : state.Shared.assignments.list.filter((a) => a.author === state.Auth.user._id).map((a) => ({
      _id: a.site._id,
      name: a.site.display,
    })),
  currentSite: state.Shared.currentSite,
}));

const Wrapper = (C) => withRouter(withStore(C));
// const serializedState = localStorage.getItem('redux');
// const shared = serializedState && JSON.parse(serializedState).Shared;
class Session extends Component {
  componentDidMount() {
    const {
      user, history, location,
    } = this.props;
    const isAdmin = user.group === 'admin';

    if (!isAdmin && location.pathname === '/') {
      history.push('/sites');
    }
  }


  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch($selectLanguage('delete'));
    dispatch($logout());
  }

  handleContextChange = (siteId) => {
    const { dispatch } = this.props;
    dispatch($setSiteAsContext(siteId));
  }

  render() {
    const {
      user,
      mySites,
      currentSite,
      location,
    } = this.props;

    const isAdmin = user.group === 'admin';

    const showContextSelector = (
      location.pathname === '/attractions'
      || location.pathname === '/tours'
      || location.pathname === '/team'
    );

    const content = (
      <Menu>
        <Menu.Item>
          <Link to="/profile">Mon profil</Link>
        </Menu.Item>

        <Menu.Item onClick={this.handleLogout}>
          <Icon type="export" />
          <span>Se déconnecter</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Layout.Header className="Header">
          <Row type="flex">
            <Col>
              <Link to="/">
                <img width={50} alt="logo" src={LOGO} />
              </Link>
            </Col>

            <Col style={{ marginLeft: '15px' }}>
              {currentSite ? (
                <Select
                  defaultValue={currentSite}
                  style={{ width: 200 }}
                  disabled={!showContextSelector}
                  onChange={this.handleContextChange}
                >
                  {mySites.map((site) => (
                    <Select.Option key={site._id} value={site._id}>
                      {site.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Tooltip
                  title={`
                    Veuillez contacter l'administrateur si vous souhaitez travailler sur un 
                    ou plusieurs sites.
                  `}
                >
                  <span>
                    Aucun site
                  </span>
                </Tooltip>
              )}
            </Col>

            <Col style={{ marginLeft: 'auto' }}>
              <Row type="flex">
                <Col>
                  <Tag color={isAdmin ? '#E21F2A' : '#0D182F'}>
                    {isAdmin ? 'Admin' : 'Conservateur'}
                  </Tag>
                </Col>
                <Col>
                  <Dropdown overlay={content} trigger={['click']} placement="bottomRight">
                    <Button size="large" style={{ border: 'none', overflow: 'none' }}>
                      <UserAvatar user={user} />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout.Header>

        <Layout>
          <Layout.Sider collapsed>
            <Menu
              selectedKeys={[`/${location.pathname.split('/')[1]}`]}
              onClick={this.handleClick}
              className="SiderMenu"
            >
              <Menu.ItemGroup>
                {isAdmin && (
                  <Menu.Item key="/">
                    <Link to="/">
                      <Icon type="dashboard" />
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                )}

                <Menu.Item key="/sites">
                  <Link to="/sites">
                    <Icon type="appstore" />
                    <span>Sites</span>
                  </Link>
                </Menu.Item>

                {isAdmin && (
                  <Menu.Item key="/users">
                    <Link to={isAdmin ? '/users' : '/team'}>
                      <Icon type="user-add" />
                      <span>Utilisateurs</span>
                    </Link>
                  </Menu.Item>
                )}
              </Menu.ItemGroup>

              <Menu.ItemGroup>
                <Menu.Item key="/attractions">
                  <Link to="/attractions">
                    <Icon type="environment" />
                    <span>Lieux</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/tours">
                  <Link to="/tours">
                    <Icon type="compass" />
                    <span>Parcours</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/team">
                  <Link to="/team">
                    <Icon type="team" />
                    <span>Conservateurs</span>
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          </Layout.Sider>

          <Layout.Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            <Switch>
              <Route path="/sites" component={SitesRouter} />
              <Route path="/attractions" component={AttractionsRouter} />
              <Route path="/tours" component={ToursRouter} />
              <Route path="/users" component={UserRouter} />
              <Route path="/team" component={TeamRouter} />
              <Route path="/profile" component={ProfileView} />
              <Route path="/" component={Home} />
            </Switch>
          </Layout.Content>
        </Layout>

      </Layout>
    );
  }
}

Session.propTypes = {
  user: PropTypes.object.isRequired,
  mySites: PropTypes.array.isRequired,
  currentSite: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Session.defaultProps = {
  currentSite: null,
};

export default Wrapper(Session);
