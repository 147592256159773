import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  List, Row, Button, Modal, notification,
} from 'antd';

import AttractionRow from './AttractionRow';
import { $fetchAttractionList, $removeAttraction } from './state';

const withStore = connect((state) => ({
  attractions: state.Attractions.list
    ? state.Attractions.list.filter((attraction) => attraction.site._id === state.Shared.currentSite)
    : [],
}));

const Wrapper = (C) => withStore(C);

class AttractionsView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchAttractionList());
  }

  handleDeleteAttraction = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer ce lieux ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeAttraction(id))
          .then(() => {
            notification.success({ message: 'Le lieux a été supprimé avec succès' });
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  render() {
    const { attractions } = this.props;

    return (
      <Fragment>
        <Row type="flex" justify="end" style={{ marginBottom: 12 }}>
          <Button type="primary">
            <Link to="/attractions/new">Créer un lieu</Link>
          </Button>
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={attractions}
          renderItem={(attraction) => (
            <AttractionRow
              attraction={attraction}
              manageable
              onDelete={this.handleDeleteAttraction}
            />
          )}
        />
      </Fragment>
    );
  }
}

AttractionsView.propTypes = {
  attractions: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(AttractionsView);
