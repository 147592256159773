import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Layout, Card,
} from 'antd';

import LOGO from '../assets/logo.png';

const withStore = connect((state) => ({
  processing: state.Activity.processingByTopic['Auth.$login'] || false,
}));

const Wrapper = (C) => withStore(C);

class Language extends Component {
  state = {

  };

  handlerClick = (lan) => {
    const { history, choosLanguage } = this.props;
    history.push('/');
    choosLanguage(lan);
    window.location.reload(false);
  };

  render() {
    // const { choosLanguage, history } = this.props;


    return (
      <Layout
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Card style={{ width: 300 }}>
          <img src={LOGO} alt="logo" style={{ display: 'flex', margin: '16px auto', width: 100 }} />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={() => this.handlerClick('fr')}>Français</Button>
            <Button onClick={() => this.handlerClick('en')}>Anglais</Button>
          </div>
        </Card>
      </Layout>


    );
  }
}

Language.propTypes = {
//   dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  choosLanguage: PropTypes.func.isRequired,
};

export default Wrapper(Language);
