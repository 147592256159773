import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  List, Skeleton, Avatar, Icon, Button,
} from 'antd';

import { API_ENDPOINT } from '../common/config';

const AttractionRow = ({ attraction, manageable, onDelete }) => {
  return (
    <List.Item
      className="ListItem"
      actions={manageable ? [
        <Button
          icon="delete"
          onClick={() => onDelete(attraction._id)}
          style={{ border: 'none' }}
        />,
        <Link to={`/attractions/${attraction._id}/edit`}>
          <Icon type="edit" />
        </Link>,
      ] : []}
    >
      <Skeleton avatar title={false} loading={false} active>
        <List.Item.Meta
          avatar={(
            <Avatar
              shape="square"
              // eslint-disable-next-line no-nested-ternary
              src={attraction.coverPicture ? attraction.coverPicture.path
            && attraction.coverPicture.path.indexOf('storage/uploads') !== -1
                ? `${API_ENDPOINT}/${attraction.coverPicture.path}` : `${API_ENDPOINT}/storage/uploads/${attraction.coverPicture.path}`
                : null}
            />
)}
          title={manageable ? (
            <Link to={`/attractions/${attraction._id}/edit`}>
              {attraction.name}
            </Link>
          ) : (
            <span>{attraction.name}</span>
          )}
          // eslint-disable-next-line react/no-danger
          description={<div dangerouslySetInnerHTML={{ __html: attraction.description }} />}
        />
      </Skeleton>
    </List.Item>
  );
};

AttractionRow.propTypes = {
  attraction: PropTypes.object.isRequired,
  manageable: PropTypes.bool,
  onDelete: PropTypes.func,
};

AttractionRow.defaultProps = {
  manageable: false,
  onDelete: () => {},
};

export default AttractionRow;
