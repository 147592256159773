import { API_ENDPOINT } from '../common/config';

import * as Logger from '../common/logger';

import * as FetchHelper from '../common/fetch.helper';
import * as StateHelper from '../common/state.helper';

import { AuthService } from '../Auth/Auth.service';

import * as Auth from '../Auth/state';

import * as Activity from './Activity.state';
import { $fetchSiteList } from '../Sites/state';
import { $fetchUserList } from '../Users/state';

/**
 * Module Name
 */

export const MODULE = 'Shared';

/**
 * Initial State
 */

const INITIAL_STATE = {
  ready: false,
  initialized: false,
  language: null,
  zones: {},
  categories: {},
  subCategories: {},
  currentSite: null,
  assignments: null,
  types: {},
};

/**
 * Fetch types
 */

const fetchTypeList = StateHelper.createAsyncOperation(MODULE, 'fetchTypeList');

export function $fetchTypeList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchTypeList.name));
    dispatch(fetchTypeList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/Type?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchTypeList.success({ result })))
      .catch((error) => dispatch(fetchTypeList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchTypeList.name)));
  };
}

/**
 * Create a type
 */

const createType = StateHelper.createAsyncOperation(MODULE, 'createType');

export function $createType(payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $createType.name));
    dispatch(createType.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Type?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: payload,
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(createType.success({ result })))
      .then(() => dispatch($fetchTypeList()))
      .catch((error) => dispatch(createType.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createType.name)));
  };
}
/**
 * Edit a type
 */

const editType = StateHelper.createAsyncOperation(MODULE, 'editType');

export function $editType(id, payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $editType.name));
    dispatch(editType.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Type?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(editType.success({ result })))
      .then(() => dispatch($fetchTypeList()))
      .catch((error) => dispatch(editType.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editType.name)));
  };
}
/**
 * Delete a type
 */

const removeType = StateHelper.createAsyncOperation(MODULE, 'removeType');

export function $removeType(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeType.name));
    dispatch(removeType.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Type?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeType.success({ result })))
      .then(() => dispatch($fetchTypeList()))
      .catch((error) => dispatch(removeType.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeType.name)));
  };
}

/**
 * Fetch Categories
 */

const fetchCategoryList = StateHelper.createAsyncOperation(MODULE, 'fetchCategoryList');

export function $fetchCategoryList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchCategoryList.name));
    dispatch(fetchCategoryList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/Category?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchCategoryList.success({ result })))
      .catch((error) => dispatch(fetchCategoryList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchCategoryList.name)));
  };
}
/**
 * Create a category
 */

const createCategory = StateHelper.createAsyncOperation(MODULE, 'createCategory');

export function $createCategory(payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $createCategory.name));
    dispatch(createCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Category?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: payload,
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(createCategory.success({ result })))
      .then(() => dispatch($fetchCategoryList()))
      .catch((error) => dispatch(createCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createCategory.name)));
  };
}

/**
 * Edit a category
 */

const editCategory = StateHelper.createAsyncOperation(MODULE, 'editCategory');

export function $editCategory(id, payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $editCategory.name));
    dispatch(editCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Category?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(editCategory.success({ result })))
      .then(() => dispatch($fetchCategoryList()))
      .catch((error) => dispatch(editCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editCategory.name)));
  };
}

/**
 * Delete a category
 */

const removeCategory = StateHelper.createAsyncOperation(MODULE, 'removeCategory');

export function $removeCategory(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $editCategory.name));
    dispatch(editCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Category?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeCategory.success({ result })))
      .then(() => dispatch($fetchCategoryList()))
      .catch((error) => dispatch(editCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeCategory.name)));
  };
}

/**
 * Fetch Sub Categories
 */

const fetchSubCategoryList = StateHelper.createAsyncOperation(MODULE, 'fetchSubCategoryList');

export function $fetchSubCategoryList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchSubCategoryList.name));
    dispatch(fetchSubCategoryList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/TourSubCategory?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchSubCategoryList.success({ result })))
      .catch((error) => dispatch(fetchSubCategoryList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchSubCategoryList.name)));
  };
}

/**
 * Create a subcategory
 */

const createSubCategory = StateHelper.createAsyncOperation(MODULE, 'createSubCategory');

export function $createSubCategory(payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $createSubCategory.name));
    dispatch(createSubCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/TourSubCategory?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: payload,
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(createSubCategory.success({ result })))
      .then(() => dispatch($fetchSubCategoryList()))
      .catch((error) => dispatch(createSubCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createSubCategory.name)));
  };
}

/**
 * Edit a sub category
 */

const editSubCategory = StateHelper.createAsyncOperation(MODULE, 'editSubCategory');

export function $editSubCategory(id, payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $editSubCategory.name));
    dispatch(editSubCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/TourSubCategory?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(editSubCategory.success({ result })))
      .then(() => dispatch($fetchSubCategoryList()))
      .catch((error) => dispatch(editSubCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editSubCategory.name)));
  };
}

/**
 * Delete a category
 */

const removeSubCategory = StateHelper.createAsyncOperation(MODULE, 'removeSubCategory');

export function $removeSubCategory(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeSubCategory.name));
    dispatch(removeSubCategory.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/TourSubCategory?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeSubCategory.success({ result })))
      .then(() => dispatch($fetchSubCategoryList()))
      .catch((error) => dispatch(removeSubCategory.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeSubCategory.name)));
  };
}

/**
 * Fetch Zones
 */

const fetchZoneList = StateHelper.createAsyncOperation(MODULE, 'fetchZoneList');

export function $fetchZoneList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchZoneList.name));
    dispatch(fetchZoneList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/Zone?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchZoneList.success({ result })))
      .catch((error) => dispatch(fetchZoneList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchZoneList.name)));
  };
}

/**
 * Create a Zone
 */

const createZone = StateHelper.createAsyncOperation(MODULE, 'createZone');

export function $createZone(payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $createZone.name));
    dispatch(createZone.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Zone?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: payload,
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(createZone.success({ result })))
      .then(() => dispatch($fetchZoneList()))
      .catch((error) => dispatch(createZone.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createZone.name)));
  };
}

/**
 * Edit a Zone
 */

const editZone = StateHelper.createAsyncOperation(MODULE, 'editZone');

export function $editZone(id, payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $editZone.name));
    dispatch(editZone.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Zone?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(editZone.success({ result })))
      .then(() => dispatch($fetchZoneList()))
      .catch((error) => dispatch(editZone.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editZone.name)));
  };
}

/**
 * Delete a Zone
 */

const removeZone = StateHelper.createAsyncOperation(MODULE, 'removeCategory');

export function $removeZone(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeZone.name));
    dispatch(removeZone.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Zone?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeZone.success({ result })))
      .then(() => dispatch($fetchZoneList()))
      .catch((error) => dispatch(removeZone.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeZone.name)));
  };
}

/**
 * File uploads
 */
const uploadFile = StateHelper.createAsyncOperation(MODULE, 'uploadFile');

export function $uploadFile(file) {
  return (dispatch, getState) => {
    dispatch(uploadFile.request());
    const formData = new FormData();
    // XXX Only master key allows asset uploads
    formData.append('_by', getState().Auth.user._id);
    formData.append('files[]', file);
    if (Array.isArray(file)) {
      file.map((elt, index) => formData.append(`files[${index}]`, elt));
      return fetch(`${API_ENDPOINT}/api/cockpit/addAssets?token=${AuthService.getAccessToken()}`, {
        method: 'post',
        body: formData,
      })
        .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
        .then((result) => dispatch(uploadFile.success(result)))
        .catch((error) => dispatch(uploadFile.failure(error)));
    }

    return fetch(`${API_ENDPOINT}/api/cockpit/addAssets?token=${AuthService.getAccessToken()}`, {
      method: 'post',
      body: formData,
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(uploadFile.success(result)))
      .catch((error) => dispatch(uploadFile.failure(error)));
  };
}
/**
 * Change context: current site for authors
 */

const setSiteAsContext = StateHelper.createSimpleOperation(MODULE, 'setSiteAsContext');

export function $setSiteAsContext(siteId) {
  return (dispatch) => {
    return dispatch(setSiteAsContext.action({ meta: { siteId } }));
  };
}

/**
 * Fetch assignment list: Authors assignments to sites
 */

const fetchAssignmentList = StateHelper.createAsyncOperation(MODULE, 'fetchAssignmentList');

export function $fetchAssignmentList(siteId) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchAssignmentList.name));
    dispatch(fetchAssignmentList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/Assignment?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _filter: {
          site: { _id: siteId },
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchAssignmentList.success({ result })))
      .catch((error) => dispatch(fetchAssignmentList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchAssignmentList.name)));
  };
}

/**
 * Add author assignment to a site
 */

const addAuthorAssignment = StateHelper.createAsyncOperation(MODULE, 'addAuthorAssignment');

export function $addAuthorAssignment(site, authorId) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $addAuthorAssignment.name));
    dispatch(addAuthorAssignment.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Assignment?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          site,
          author: authorId,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(addAuthorAssignment.success({ result })))
      .catch((error) => dispatch(addAuthorAssignment.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $addAuthorAssignment.name)));
  };
}

/**
 * Remove auhtor assignment
 */

const removeAuthorAssignment = StateHelper.createAsyncOperation(MODULE, 'removeAuthorAssignment');

export function $removeAuthorAssignment(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeAuthorAssignment.name));
    dispatch(removeZone.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Assignment?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeAuthorAssignment.success({ result, meta: { id } })))
      .catch((error) => dispatch(removeAuthorAssignment.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeAuthorAssignment.name)));
  };
}

/**
 * Ready app
 */

const ready = StateHelper.createSimpleOperation(MODULE, 'ready');

export function $ready() {
  Logger.debug('$ready');

  return async (dispatch) => {
    dispatch(ready.action());
  };
}

/**
 * Initialize app
 */

const initialize = StateHelper.createSimpleOperation(MODULE, 'initialize');

export function $initialize() {
  Logger.debug('$initialize');

  return async (dispatch, getState) => {
    // console.log('getState', getState());
    // We only should load resources necessary to app functioning
    // Eg. We don't need to load Tours, because those are specific to a site
    // Tours, Attractions are loaded per component
    await Promise.all([
      new Promise((resolve) => setTimeout(resolve, 2000)),
      dispatch($fetchZoneList()),
      dispatch($fetchCategoryList()),
      dispatch($fetchSubCategoryList()),
      dispatch($fetchUserList()),
      dispatch($fetchAssignmentList()),
      dispatch($fetchSiteList()),
      dispatch($fetchTypeList()),
      // dispatch($selectLanguage(null)),
    ]);

    const state = getState();
    // Admin user has all sites available. However, authors are assigned per site level
    const mySites = state.Auth.user.group === 'admin'
      ? state.Sites.list
      : state.Shared.assignments.list
        .filter((a) => a.author === state.Auth.user._id)
        .map((a) => ({
          _id: a.site._id,
          name: a.site.display,
        }));

    if (mySites.length > 0) {
      dispatch($setSiteAsContext(mySites[0]._id));
    }

    return dispatch(initialize.action());
  };
}

/**
 * Uninitialize app
 */

const uninitialize = StateHelper.createSimpleOperation(MODULE, 'uninitialize');

export function $uninitialize() {
  Logger.debug('$uninitialize');

  return async (dispatch) => {
    dispatch(uninitialize.action());
  };
}

/**
 * Language select
 */

const selectLanguage = StateHelper.createSimpleOperation(MODULE, 'selectLanguage');
export const $selectLanguage1 = selectLanguage.action;
export function $selectLanguage(lang) {
  return async (dispatch) => {
    await dispatch($selectLanguage1({ lang }));
    if (lang !== 'delete') {
      await dispatch($initialize()).catch((error) => dispatch(Activity.$toast('failure', error.message)));
    }
  };
}
export function setLanguage(state, newstate) {
  let lang = null;
  if (newstate && newstate !== 'delete') {
    lang = newstate;
    // return newstate;
  }
  if (newstate === 'delete') {
    lang = null;
    // return null;
  }
  if (state && !newstate) {
    lang = state;
    // return state;
  }
  return lang;
}

/**
 * Reducer
 */

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ready.TYPE:
      return {
        ...state,
        ready: true,
      };

    case initialize.TYPE:
      return {
        ...state,
        initialized: true,
      };

    case uninitialize.TYPE:
      return {
        ...state,
        initialized: false,
      };
    case selectLanguage.TYPE:
      return {
        ...state,
        language: setLanguage(state.language, action.lang),
      };

    case fetchCategoryList.SUCCESS:
      return {
        ...state,
        categories: {
          list: action.result.entries,
          fields: action.result.fields,
          total: action.result.total,
        },
      };

    case fetchSubCategoryList.SUCCESS:
      return {
        ...state,
        subCategories: {
          list: action.result.entries,
          fields: action.result.fields,
          total: action.result.total,
        },
      };

    case fetchZoneList.SUCCESS:
      return {
        ...state,
        zones: {
          list: action.result.entries,
          fields: action.result.fields,
          total: action.result.total,
        },
      };

    case fetchTypeList.SUCCESS:
      return {
        ...state,
        types: {
          list: action.result.entries,
          fields: action.result.fields,
          total: action.result.total,
        },
      };

    case setSiteAsContext.TYPE:
      return {
        ...state,
        currentSite: action.meta.siteId,
      };

    case fetchAssignmentList.SUCCESS:
      return {
        ...state,
        assignments: {
          list: action.result.entries,
          fields: action.result.fields,
          total: action.result.total,
        },
      };

    case addAuthorAssignment.SUCCESS:
      return {
        ...state,
        assignments: {
          ...state.assignments,
          list: state.assignments.list.concat(action.result),
          total: state.assignments.total + 1,
        },
      };

    case removeAuthorAssignment.SUCCESS:
      return {
        ...state,
        assignments: {
          ...state.assignments,
          list: state.assignments.list.filter((a) => a._id !== action.meta.id),
          total: state.assignments.total - 1,
        },
      };

    default:
      return state;
  }
}

/**
 * App initializer
 */

export async function initializer({ dispatch, getState }) {
  // const serializedState = localStorage.getItem('redux');
  // const shared = serializedState && JSON.parse(serializedState).Shared;
  FetchHelper.events.on('failure', (error, response) => {
    if (AuthService.isAuthenticated() && response.status === 401) {
      dispatch(Auth.$logout());
    }
  });

  await AuthService.initialize();

  if (!AuthService.isAuthenticated() && getState().Auth.authenticated) {
    dispatch(Auth.$reset());
  }

  dispatch($ready());
  if (AuthService.isAuthenticated() && getState().Shared.language) {
    dispatch($uninitialize());
    dispatch($initialize()).catch((error) => dispatch(Activity.$toast('failure', error.message)));
  }
  if (getState().Shared.language) {
    dispatch($uninitialize());
    dispatch($initialize()).catch((error) => dispatch(Activity.$toast('failure', error.message)));
  }
  // console.log('shared.language0', shared.language);

  AuthService.events.on('logout', () => {
    dispatch($uninitialize());
  });
}

export function persister({ language }) {
  return {
    language,
  };
}
