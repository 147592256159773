import React from 'react';
import { Icon } from 'antd';

import './Loading.css';

export const Loading = () => {
  return <Icon type="loading" className="Loading" />;
};

export default Loading;
