import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Upload, Icon, message,
} from 'antd';

import { $uploadFile, $createType, $editType } from './state';
import { ASSETS_ENDPOINT } from '../common/config';
import { isValidImage } from '../common/validate';

const withStore = connect((state, props) => ({
  type: state.Shared.types.list.find((c) => c._id === props.id),
}));

const Wrapper = (C) => withStore(C);

class TypeForm extends Component {
  state = {
    isUploading: false,
  };

  componentDidMount() {
    const { form } = this.props;

    form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      type, form, onCloseModal, dispatch,
    } = this.props;

    // eslint-disable-next-line consistent-return
    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      let isValid = true;
      if (values.imageFile && values.imageFile.file) {
        isValid = isValidImage(values.imageFile.file);
      }

      if (!isValid) {
        message.error('Merci de sélectionner un fichier valide');
      }

      this.setState({ isUploading: true });
      this.uploadFileBeforeSubmit(values).then((valuesWithAssets) => {
        this.setState({ isUploading: false });

        const newValues = this.mapFormValues(valuesWithAssets);

        if (type) {
          dispatch($editType(type._id, newValues)).then(() => {
            onCloseModal();
          });
        } else {
          dispatch($createType(newValues)).then(() => {
            onCloseModal();
          });
        }
      });
    });
  };

  uploadFileBeforeSubmit(values) {
    const { dispatch } = this.props;

    if (values.imageFile && values.imageFile.file) {
      return dispatch($uploadFile(values.imageFile.file)).then((result) => {
        return {
          ...values,
          imageFile: {
            ...values.imageFile,
            file: { ...values.imageFile.file, response: result.assets[0] },
          },
        };
      });
    }

    return Promise.resolve(values);
  }

  mapFormValues(values) {
    const preparedValues = { ...values };

    if (preparedValues.imageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.image = preparedValues.imageFile.file.response;
      delete preparedValues.imageFile;
    }


    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }


  render() {
    const { isUploading } = this.state;
    const { form, type } = this.props;

    const nameHasError = this.hasError('label');
    const hasSelectedImageFile = form.getFieldValue('imageFile') && form.getFieldValue('imageFile').fileList.length > 0;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item key="label" label="Label" validateStatus={nameHasError ? 'error' : 'success'} help={nameHasError || ''}>
          {form.getFieldDecorator('label', {
            rules: [{ required: true, message: 'Entrez le nom' }],
            initialValue: type ? type.label : '',
          })(<Input placeholder="Type label" />)}
        </Form.Item>

        <Form.Item key="imageFile" label="Image" required>
          {form.getFieldDecorator('imageFile')(
            <Upload
              name="files[]"
              accept="image/*"
              listType="picture"
              defaultFileList={
                type && type.image
                  ? [
                    {
                      uid: type.image._id,
                      name: 'Current picture',
                      status: 'done',
                      url: `${ASSETS_ENDPOINT}/${type.image.path}`,
                    },
                  ]
                  : []
              }
              beforeUpload={() => {
                return false;
              }}
            >
              {!hasSelectedImageFile && (
                <Button>
                  <Icon type="upload" />
                  Sélectionner un fichier
                </Button>
              )}
            </Upload>,
          )}
          {!hasSelectedImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button type="primary" htmlType="submit" disabled={isUploading}>
            {isUploading ? 'En cours...' : 'Envoyer'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

TypeForm.propTypes = {

  type: PropTypes.object,
  form: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

TypeForm.defaultProps = {

  type: null,
};

const WrappedTypeForm = Form.create({ name: 'Type_form' })(TypeForm);

export default Wrapper(WrappedTypeForm);
