import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './common/init';

import AppContainer from './AppContainer';

import { unregister as unregisterServiceWorker } from './registerServiceWorker';
Sentry.init({dsn: "https://34db728972ed490dab3babe11025d8e1@sentry.emiketic.com/8"});// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<AppContainer />, document.getElementById('app'));
// registerServiceWorker()
unregisterServiceWorker();
