/* eslint-disable indent */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Form, Button, Upload, Icon, message, Row, Col, notification, Select } from 'antd';
import { Map, TileLayer, Marker } from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import ReactQuill from 'react-quill';

import { $editAttraction, $createAttraction, $fetchAttractionList } from './state';
import { $uploadFile } from '../Shared/state';
import { ASSETS_ENDPOINT, API_ENDPOINT } from '../common/config';
import { isValidImage, isValidAudio, isValidVideo } from '../common/validate';

const DEFAULT_MAP_ZOOM = 8;
const field = {
  type: 'set',
  label: '',
  options: {
    fields: [
      {
        name: 'description',
        type: 'text',
      },
      {
        name: 'image',
        type: 'asset',
      },
    ],
  },
};
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 18, offset: 4 },
};

const mapYoutubeVideosField = (urls) =>
  urls.map((url) => ({
    field: {
      type: 'set',
      label: '',
      options: {
        fields: [
          {
            name: 'url',
            type: 'text',
          },
        ],
      },
    },
    value: { url },
  }));

const mapLikeField = (entries) =>
  entries.map((entry) => ({
    field: {
      type: 'text',
    },
    value: entry,
  }));

const mapTimelineField = (entries) =>
  entries.map((entry) => ({
    field: {
      type: 'set',
      label: '',
      options: {
        fields: [
          {
            name: 'date',
            type: 'text',
          },
          {
            name: 'title',
            type: 'text',
          },
        ],
      },
    },
    value: {
      date: entry.date,
      title: entry.title,
    },
  }));

const makeQRCode = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const withStore = connect((state, props) => ({
  processing: state.Activity.processingByTopic['Attractions.$fetchAttractionList'] || false,
  attraction: state.Attractions.list && state.Attractions.list.find((a) => a._id === props.match.params.id),
  types: state.Shared.types.list,
  mySites:
    state.Auth.user.group === 'admin'
      ? state.Sites.list
      : state.Shared.assignments.list
          .filter((a) => a.author === state.Auth.user._id)
          .map((a) => ({
            _id: a.site._id,
            name: a.site.display,
          })),
  sites: state.Sites.list,
  currentSite: state.Shared.currentSite,
}));

const Wrapper = (C) => withStore(C);

class AttractionForm extends Component {
  refmarker = createRef();

  state = {
    qrcode: makeQRCode(),
    isUploading: false,
    removedImages: [],
  };

  componentDidMount() {
    const { form, dispatch } = this.props;
    console.log(
      '🚀 ~ file: AttractionForm.js ~ line 141 ~ AttractionForm ~ componentDidMount ~ props.match.params.id',
      this.props.match.params.id,
    );
    dispatch($fetchAttractionList()).then(() => {
      // eslint-disable-next-line no-unused-vars
      const qrcode = new window.QRCode('qrcode', {
        text: form.getFieldValue('qrcode'),
        width: 128,
        height: 128,
      });
    });
  }

  setPhotos = (images) => {
    const allphotos = [];
    images.forEach((elt) => {
      allphotos.push({
        uid: elt.path,
        name: 'Current picture',
        status: 'done',
        url:
          elt.path.indexOf('storage/uploads') !== -1
            ? `${API_ENDPOINT}/${elt.path}`
            : `${API_ENDPOINT}/storage/uploads${elt.path}`,
      });
    });
    return allphotos;
  };

  // eslint-disable-next-line react/sort-comp
  handleSubmit = (e) => {
    e.preventDefault();

    const { attraction, form, history, dispatch } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      let isValid = true;
      if (values.imageFile && values.imageFile.file) {
        isValid = isValidImage(values.imageFile.file);
      }
      if (values.audioFile && values.audioFile.file) {
        isValid = isValidAudio(values.audioFile.file);
      }
      if (values.videoFile && values.videoFile.file) {
        isValid = isValidVideo(values.videoFile.file);
      }

      if (!isValid) {
        message.error('Le formulaire contient des fichiers invalides');
        return null;
      }

      this.setState({ isUploading: true });
      this.uploadFilesBeforeSubmit(values).then((valuesWithAssets) => {
        this.setState({ isUploading: false });
        const newValues = this.mapFormValues(valuesWithAssets);

        if (attraction) {
          dispatch($editAttraction(attraction._id, newValues)).then(() => {
            dispatch($fetchAttractionList());
            notification.success({ message: 'Le lieux a été édité avec succès!' });
            history.push('/attractions');
          });
        } else {
          dispatch($createAttraction(newValues)).then(() => {
            dispatch($fetchAttractionList());
            notification.success({ message: 'Le lieux a été créé avec succès!' });
            history.push('/attractions');
          });
        }
      });
    });
  };

  pathFix(content) {
    const contentString = JSON.stringify(content);
    if (contentString && contentString.indexOf('storage/uploads/') === -1) {
      const fixedContentString = contentString.replace(/"path":"\//g, '"path":"/storage/uploads/');
      const result = JSON.parse(fixedContentString);
      return result;
    }

    return content;
  }

  handleUpdatePosition = () => {
    const { form } = this.props;
    const marker = this.refmarker.current;

    if (marker != null) {
      form.setFieldsValue({
        ...marker.leafletElement.getLatLng(),
      });
    }
  };

  // eslint-disable-next-line react/sort-comp
  mapFormValues(values) {
    const { currentSite, mySites, types, attraction } = this.props;
    const { removedImages } = this.state;
    const preparedValues = { ...values };

    // console.log('before', values);

    preparedValues.types = values.types.map((type) => ({
      link: 'Type',
      _id: type,
      display: types.find((t) => t._id === type).label,
    }));

    preparedValues.location = {
      lat: preparedValues.lat,
      lng: preparedValues.lng,
    };

    preparedValues.site = {
      link: 'Site',
      _id: currentSite,
      display: mySites.find((s) => s._id === currentSite).name,
    };

    if (preparedValues.imageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.coverPicture = preparedValues.imageFile.file.response;
      delete preparedValues.imageFile;
    }

    if (preparedValues.Gallery) {
      // eslint-disable-next-line prefer-destructuring
      let result = [];
      if (attraction && attraction.images) {
        attraction.images.forEach((elt, index) => {
          // eslint-disable-next-line max-len
          return result.push({
            field,
            value: {
              description: preparedValues.Gallery[index]
                ? preparedValues.Gallery[index].description
                : elt.value.description,
              image: elt.value.image,
            },
          });
          // const exist = [...preparedValues.Gallery].filter((item) => item.image._id === elt.value.image._id);
          // console.log('exist', exist);
          // return result.push({ field, value: { description: exist[0] ? exist[0].description : elt.value.description, image: elt.value.image } });
        });
      }
      if (removedImages.length > 0 && result) {
        removedImages.forEach((item) => {
          result = result.filter((elt, index) => elt && index !== item);
        });
      }

      if (preparedValues.Gallery.file) {
        const { descriptions } = preparedValues.Gallery;
        preparedValues.Gallery.file.response.map((image, index) =>
          result.push({ field, value: { description: descriptions[index], image } }),
        );
      }
      preparedValues.images = [...result];
      delete preparedValues.Gallery;
      delete preparedValues.galleryKeys;
    }
    if (preparedValues.audioFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.audioTrack = preparedValues.audioFile.file.response;
      delete preparedValues.audioFile;
    }

    if (preparedValues.videoFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.video = preparedValues.videoFile.file.response;
      delete preparedValues.videoFile;
    }

    if (preparedValues.youtubeVideos) {
      preparedValues.youtubeVideos = mapYoutubeVideosField(preparedValues.youtubeVideos.filter((v) => v !== null));
      delete preparedValues.youtubeVideosKeys;
    }
    if (preparedValues.like) {
      preparedValues.likes = mapLikeField(preparedValues.like.filter((t) => t !== null));
      delete preparedValues.likeKeys;
    }

    if (preparedValues.timeline) {
      preparedValues.timeline = mapTimelineField(preparedValues.timeline.filter((t) => t !== null));
      delete preparedValues.timelineKeys;
    }

    return preparedValues;
  }

  uploadFilesBeforeSubmit(values) {
    if (!values.imageFile && !values.audioFile && !values.videoFile && !values.galleryFile && !values.Gallery) {
      return Promise.resolve(values);
    }

    const { dispatch } = this.props;
    const promises = [];

    if (values.imageFile && values.imageFile.file) {
      promises.push(
        dispatch($uploadFile(values.imageFile.file)).then((result) => {
          return {
            ...values,
            imageFile: {
              ...values.imageFile,
              file: { ...values.imageFile.file, response: result.assets[0] },
            },
          };
        }),
      );
    }

    if (values.Gallery) {
      const descriptions = [];
      const photos = [];
      values.Gallery.forEach((elt) => {
        if (elt.image && elt.image.file) {
          photos.push(elt.image.file);
          descriptions.push(elt.description ? elt.description : '');
        }
      });

      promises.push(
        dispatch($uploadFile(photos)).then((result) => {
          return {
            Gallery: {
              ...values.Gallery,
              descriptions,
              file: { ...values.Gallery, response: result.assets },
            },
          };
        }),
      );
    }
    if (values.audioFile && values.audioFile.file) {
      promises.push(
        dispatch($uploadFile(values.audioFile.file)).then((result) => {
          return {
            audioFile: {
              ...values.audioFile,
              file: { ...values.audioFile.file, response: result.assets[0] },
            },
          };
        }),
      );
    }

    if (values.videoFile && values.videoFile.file) {
      promises.push(
        dispatch($uploadFile(values.videoFile.file)).then((result) => {
          return {
            videoFile: {
              ...values.videoFile,
              file: { ...values.videoFile.file, response: result.assets[0] },
            },
          };
        }),
      );
    }

    return Promise.all(promises).then((results) => {
      let v = { ...values };
      results.forEach((r) => {
        v = { ...v, ...r };
      });
      return v;
    });
  }

  previewAudioFile(file) {
    if (!isValidAudio(file)) {
      message.error('Fichier audio invalide');
      return false;
    }

    const reader = new FileReader();
    const audioEl = document.getElementById('audio-preview');
    reader.onload = (e) => {
      audioEl.setAttribute('src', e.target.result);
    };
    reader.readAsDataURL(file);

    return false;
  }

  previewVideoFile(file) {
    if (!isValidVideo(file)) {
      message.error('Fichier vidéo invalide');
      return false;
    }

    const reader = new FileReader();
    const videoEl = document.getElementById('video-preview');
    reader.onload = (e) => {
      videoEl.setAttribute('src', e.target.result);
    };
    reader.readAsDataURL(file);

    return false;
  }

  handleRemoveVideoUrl = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('youtubeVideosKeys');
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      youtubeVideosKeys: keys.filter((key) => key !== k),
    });
  };

  handleAddVideoUrl = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('youtubeVideosKeys');
    const lastKey = keys.length > 0 ? keys[keys.length - 1] : 0;

    form.setFieldsValue({
      youtubeVideosKeys: keys.concat(lastKey + 1),
    });
  };

  handleRemoveTimelineEntry = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('timelineKeys');
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      timelineKeys: keys.filter((key) => key !== k),
    });
  };

  handleRemoveGalleryEntry = (k) => {
    this.setState((prevState) => ({
      removedImages: [...prevState.removedImages, k],
    }));
    const { form } = this.props;
    const keys = form.getFieldValue('galleryKeys');
    if (keys.length === 0) {
      return;
    }

    form.setFieldsValue({
      galleryKeys: keys.filter((key) => key !== k),
    });
  };

  handleRemovelikeEntry = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('likeKeys');
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      likeKeys: keys.filter((key) => key !== k),
    });
  };

  handleAddTimelineEntry = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('timelineKeys');
    // const lastKey = keys.length > 0 ? keys[keys.length - 1] : 0;
    const id = Math.floor(Math.random() * (2000 - keys.length - 1) + keys.length - 1);
    form.setFieldsValue({
      timelineKeys: keys.concat(id),
    });
  };

  handleAddTGalleryEntry = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('galleryKeys');
    const id = Math.floor(Math.random() * (2000 - keys.length - 1) + keys.length - 1);
    form.setFieldsValue({
      galleryKeys: keys.concat(id),
    });
  };

  handleAddLikeEntry = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('likeKeys');
    // const lastKey = keys.length > 0 ? keys[keys.length - 1] : 0;
    const id = Math.floor(Math.random() * (2000 - keys.length - 1) + keys.length - 1);
    form.setFieldsValue({
      likeKeys: keys.concat(id),
    });
  };

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  getDefaultLocation() {
    const { attraction, sites, currentSite } = this.props;
    if (attraction) {
      return [attraction.location.lat, attraction.location.lng];
    }

    const site = sites.find((s) => s._id === currentSite);
    if (site) {
      return [site.location.lat, site.location.lng];
    }

    return [];
  }

  render() {
    const { isUploading, qrcode } = this.state;
    const { attraction, types, processing, form } = this.props;

    if (processing !== false) {
      return null;
    }
    const [defaultLat, defaultLng] = this.getDefaultLocation();

    const hasSelectedAudioFile = form.getFieldValue('audioFile') && form.getFieldValue('audioFile').fileList.length > 0;
    const hasSelectedImageFile = form.getFieldValue('imageFile') && form.getFieldValue('imageFile').fileList.length > 0;
    const hasSelectedVideoFile = form.getFieldValue('videoFile') && form.getFieldValue('videoFile').fileList.length > 0;
    const typesHasError = this.hasError('types');
    const nameHasError = this.hasError('name');
    const descriptionHasError = this.hasError('description');

    // Youtube multi entries setup
    const youtubeVideos = attraction && attraction.youtubeVideos ? attraction.youtubeVideos.map((t) => t.value) : [];

    form.getFieldDecorator('youtubeVideosKeys', { initialValue: youtubeVideos.map((v, i) => i) });
    const youtubeVideosKeys = form.getFieldValue('youtubeVideosKeys');
    const youtubeFormItems = youtubeVideosKeys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Youtube urls' : ''}
        required={false}
        key={k}
      >
        {form.getFieldDecorator(`youtubeVideos[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: youtubeVideos[k] ? youtubeVideos[k].url : '',
        })(
          <Input placeholder="https://www.youtube.com/watch?v=2MSe4GHubOk" style={{ width: '60%', marginRight: 8 }} />,
        )}
        {youtubeVideosKeys.length > 1 ? (
          <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.handleRemoveVideoUrl(k)} />
        ) : null}
      </Form.Item>
    ));
    const Gallery = attraction && attraction.images ? attraction.images.map((t) => t.value) : [];
    form.getFieldDecorator('galleryKeys', { initialValue: Gallery.map((v, i) => i) });
    const galleryKeys = form.getFieldValue('galleryKeys');

    const galleryFormItems = galleryKeys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Gallerie' : ''}
        required={false}
        key={k}
      >
        {form.getFieldDecorator(`Gallery[${k}][description]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: Gallery[k] ? Gallery[k].description : '',
        })(<Input placeholder="Description" style={{ width: '60%', marginRight: 8 }} />)}
        {form.getFieldDecorator(`Gallery[${k}][image]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: Gallery[k] ? Gallery[k].image : '',
        })(
          <Upload
            onRemove={() =>
              this.setState((prevState) => ({
                removedImages: [...prevState.removedImages, k],
              }))
            }
            style={{ width: '60%', marginRight: 8 }}
            name="files[]"
            accept="image/*"
            listType="picture"
            defaultFileList={
              Gallery[k] && Gallery[k].image
                ? [
                    {
                      uid: Gallery[k].image._id,
                      name: 'Image actuelle',
                      status: 'done',
                      url:
                        Gallery[k].image.path && Gallery[k].image.path.indexOf('storage/uploads') !== -1
                          ? `${API_ENDPOINT}/${Gallery[k].image.path}`
                          : `${API_ENDPOINT}/storage/uploads/${Gallery[k].image.path}`,
                    },
                  ]
                : []
            }
            beforeUpload={() => {
              return false;
            }}
          >
            <Button>
              <Icon type="upload" />
              Sélectionner un fichier
            </Button>
          </Upload>,
        )}
        {galleryKeys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.handleRemoveGalleryEntry(k)}
          />
        ) : null}
      </Form.Item>
    ));
    // Timeline multi entries setup
    const timeline = attraction && attraction.timeline ? attraction.timeline.map((t) => t.value) : [];

    form.getFieldDecorator('timelineKeys', { initialValue: timeline.map((v, i) => i) });
    const timelineKeys = form.getFieldValue('timelineKeys');
    const timelineFormItems = timelineKeys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Timeline' : ''}
        required={false}
        key={k}
      >
        {form.getFieldDecorator(`timeline[${k}][date]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: timeline[k] ? timeline[k].date : '',
        })(<Input placeholder="Année" style={{ width: '60%', marginRight: 8 }} />)}
        {form.getFieldDecorator(`timeline[${k}][title]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: timeline[k] ? timeline[k].title : '',
        })(<Input placeholder="Description" style={{ width: '60%', marginRight: 8 }} />)}
        {timelineKeys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.handleRemoveTimelineEntry(k)}
          />
        ) : null}
      </Form.Item>
    ));

    const like = attraction && attraction.likes ? attraction.likes.map((l) => l.value) : [];
    form.getFieldDecorator('likeKeys', { initialValue: like.map((v, i) => i) });
    const likeKeys = form.getFieldValue('likeKeys');
    const likeFormItems = likeKeys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Likes' : ''}
        required={false}
        key={k}
      >
        {form.getFieldDecorator(`like[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: like[k] ? like[k] : '',
        })(<Input placeholder="likes" style={{ width: '60%', marginRight: 8 }} />)}
        {likeKeys.length > 1 ? (
          <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.handleRemovelikeEntry(k)} />
        ) : null}
      </Form.Item>
    ));

    return (
      <Row>
        <Col span={18}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              key="types"
              label="Types"
              validateStatus={typesHasError ? 'error' : 'success'}
              help={typesHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('types', {
                rules: [{ required: true, message: 'Sélectionnez un type' }],
                initialValue: attraction && attraction.types ? attraction.types.map((type) => type._id) : [],
              })(
                <Select mode="multiple" placeholder="Sélectionnez un ou plusieurs types">
                  {types.map((type) => (
                    <Select.Option key={type._id} value={type._id}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              key="name"
              label="Nom"
              validateStatus={nameHasError ? 'error' : 'success'}
              help={nameHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Entrez le nom' }],
                initialValue: attraction ? attraction.name : '',
              })(<Input placeholder="Nom du lieu" />)}
            </Form.Item>

            <Form.Item
              key="description"
              label="Description"
              validateStatus={descriptionHasError ? 'error' : 'success'}
              help={descriptionHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('description', {
                rules: [{ required: true, message: 'Entrez la description' }],
                initialValue: attraction ? attraction.description : '',
              })(<ReactQuill placeholder="Décrivez le lieu pour les visiteurs " />)}
            </Form.Item>

            <Form.Item label="Position" key="location" {...formItemLayout}>
              <span>Utiliser le marqueur sur la carte our entrer lat et pour séléctionner la position</span>
              {form.getFieldDecorator('lat', {
                rules: [{ required: true }],
                initialValue: defaultLat,
              })(<Input placeholder="Latitude" />)}
              {form.getFieldDecorator('lng', {
                rules: [{ required: true }],
                initialValue: defaultLng,
              })(<Input placeholder="Longitude" />)}
            </Form.Item>

            <Form.Item {...formItemLayoutWithOutLabel}>
              <Map
                center={[defaultLat, defaultLng]}
                zoom={attraction ? 12 : DEFAULT_MAP_ZOOM}
                style={{ height: '300px' }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution=""
                  minZoom="0"
                  maxZoom="22"
                />
                <Marker
                  position={[form.getFieldValue('lat'), form.getFieldValue('lng')]}
                  draggable
                  onDragend={this.handleUpdatePosition}
                  ref={this.refmarker}
                />
                <FullscreenControl position="topleft" forceSeparateButton />
              </Map>
            </Form.Item>

            <Form.Item key="audioFile" label="Audio" {...formItemLayout}>
              {form.getFieldDecorator('audioFile')(
                <Upload
                  name="files[]"
                  accept="audio/*"
                  defaultFileList={
                    attraction && attraction.audioTrack
                      ? [
                          {
                            uid: attraction.audioTrack._id,
                            name: 'Current track',
                            status: 'done',
                            url: `${ASSETS_ENDPOINT}/${attraction.audioTrack.path}`,
                          },
                        ]
                      : []
                  }
                  beforeUpload={(file) => {
                    this.previewAudioFile(file);
                    return false;
                  }}
                  onRemove={() => {
                    document.getElementById('audio-preview').pause();
                  }}
                >
                  {!hasSelectedAudioFile && (
                    <Button>
                      <Icon type="upload" />
                      Sélectionner un fichier
                    </Button>
                  )}

                  <div style={{ display: hasSelectedAudioFile ? 'block' : 'none' }}>
                    <audio id="audio-preview" controls style={{ height: '38px' }}>
                      <track kind="captions" />
                    </audio>
                  </div>
                </Upload>,
              )}
              {!hasSelectedAudioFile && <small>Le fichier audio ne doit pas dépasser les 10MB</small>}
            </Form.Item>

            <Form.Item key="imageFile" label="Image" {...formItemLayout}>
              {form.getFieldDecorator('imageFile')(
                <Upload
                  name="files[]"
                  accept="image/*"
                  listType="picture"
                  defaultFileList={
                    attraction && attraction.coverPicture
                      ? [
                          {
                            uid: attraction.coverPicture._id,
                            name: 'Image actuelle',
                            status: 'done',
                            url:
                              attraction.coverPicture.path &&
                              attraction.coverPicture.path.indexOf('storage/uploads') !== -1
                                ? `${API_ENDPOINT}/${attraction.coverPicture.path}`
                                : `${API_ENDPOINT}/storage/uploads/${attraction.coverPicture.path}`,
                          },
                        ]
                      : []
                  }
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {!hasSelectedImageFile && (
                    <Button>
                      <Icon type="upload" />
                      Sélectionner un fichier
                    </Button>
                  )}
                </Upload>,
              )}
              {!hasSelectedImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
            </Form.Item>

            {galleryFormItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="dashed" onClick={this.handleAddTGalleryEntry} style={{ width: '60%' }}>
                <Icon type="plus" />
                Ajouter une entrée dans le Gallerie
              </Button>
            </Form.Item>

            <Form.Item key="videoFile" label="Vidéo" {...formItemLayout}>
              {form.getFieldDecorator('videoFile')(
                <Upload
                  name="files[]"
                  accept="video/*"
                  defaultFileList={
                    attraction && attraction.video
                      ? [
                          {
                            uid: attraction.video._id,
                            name: 'Vidéo actuelle',
                            status: 'done',
                            url: `${ASSETS_ENDPOINT}/${attraction.video.path}`,
                          },
                        ]
                      : []
                  }
                  beforeUpload={(file) => {
                    this.previewVideoFile(file);
                    return false;
                  }}
                >
                  {!hasSelectedVideoFile && (
                    <Button>
                      <Icon type="upload" />
                      Sélectionner un fichier
                    </Button>
                  )}

                  <div style={{ display: hasSelectedVideoFile ? 'block' : 'none' }}>
                    <video id="video-preview" controls style={{ width: '100%' }}>
                      <track kind="captions" />
                    </video>
                  </div>
                </Upload>,
              )}
              {!hasSelectedVideoFile && <small>Le fichier vidéo ne doit pas dépasser les 50MB</small>}
            </Form.Item>

            <Form.Item key="summary" label="Summary" {...formItemLayout}>
              {form.getFieldDecorator('summary', {
                initialValue: attraction ? attraction.summary : '',
              })(<Input />)}
            </Form.Item>

            <Form.Item key="beaconId" label="Beacon ID" {...formItemLayout}>
              {form.getFieldDecorator('beaconId', {
                initialValue: attraction ? attraction.beaconId : '',
              })(<Input placeholder="ffffffff-1234-aaaa-1a2b-a1b2c3d4e5f6" />)}
            </Form.Item>

            <Form.Item key="qrcode" label="QR Code" {...formItemLayout}>
              {form.getFieldDecorator('qrcode', {
                initialValue: attraction ? attraction.qrcode : qrcode,
              })(<Input disabled />)}
            </Form.Item>

            {youtubeFormItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="dashed" onClick={this.handleAddVideoUrl} style={{ width: '60%' }}>
                <Icon type="plus" />
                Ajouter une vidéo Youtube
              </Button>
            </Form.Item>

            {timelineFormItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="dashed" onClick={this.handleAddTimelineEntry} style={{ width: '60%' }}>
                <Icon type="plus" />
                Ajouter une entrée dans la timeline
              </Button>
            </Form.Item>

            {likeFormItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="dashed" onClick={this.handleAddLikeEntry} style={{ width: '60%' }}>
                <Icon type="plus" />
                ajouter des likes
              </Button>
            </Form.Item>

            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="primary" htmlType="submit" disabled={isUploading}>
                {isUploading ? 'En cours...' : 'Envoyer'}
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={6}>
          <div id="qrcode" />
        </Col>
      </Row>
    );
  }
}

AttractionForm.propTypes = {
  attraction: PropTypes.object,
  mySites: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  currentSite: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

AttractionForm.defaultProps = {
  attraction: null,
};

const WrappedAttractionForm = Form.create({ name: 'attraction_form' })(AttractionForm);

export default Wrapper(WrappedAttractionForm);
