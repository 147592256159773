import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';

import UserAvatar from '../components/UserAvatar';

const AuthorRow = ({ user }) => {
  return (
    <List.Item className="ListItem">
      <List.Item.Meta
        avatar={(
          <UserAvatar user={user} />
      )}
        title={user.name}
      />
    </List.Item>
  );
};

AuthorRow.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AuthorRow;
