import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, List, Row, Col, Tooltip,
} from 'antd';

import { $fetchTourList } from '../Tours/state';
import './NewTourView.css';

const withStore = connect((state, props) => ({
  site: state.Sites.list.find((site) => site._id === props.match.params.id),
  categories: state.Shared.categories.list,
  tours: state.Tours.list || [],
  processing: state.Activity.processingByTopic['Tours.$fetchTourList'] || false,
}));

const Wrapper = (C) => withStore(C);

class NewTourView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchTourList());
  }

  render() {
    const {
      processing,
      site,
      categories,
      tours,
      match,
    } = this.props;

    if (processing !== false) {
      return null;
    }

    const categoriesIds = [...new Set(tours.map((tour) => tour.category._id))];

    const filteredCategories = categories.filter((category) => {
      return categoriesIds.includes(category._id) ? categoriesIds.includes(category._id) : categories;
    });

    return (
      <div className="NewTourView">
        <h1 className="NewTourView__title">{site.name}</h1>

        <List
          bordered
          itemLayout="vertical"
          dataSource={filteredCategories}
          renderItem={(item) => (
            <List.Item
              className="category-item"
              style={{
                background: `linear-gradient(${item.colors.primary_color}, ${item.colors.secondary_color})`,
              }}
            >

              <Row>
                <Col span={2} offset={22}>
                  <Tooltip title="Créer un parcours">
                    <Link to={{
                      pathname: '/tours/new',
                      search: `?siteId=${match.params.id}&categoryId=${item._id}`,
                    }}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="plus"
                        className="NewTourView__plus"
                      />
                    </Link>
                  </Tooltip>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <h2 className="NewTourView__desc-title">{item.name}</h2>
                  <p className="NewTourView__desc-text">
                    {item.description}
                  </p>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

NewTourView.propTypes = {
  site: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  tours: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default Wrapper(NewTourView);
