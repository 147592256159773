import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'antd';

import AuthorRow from '../Users/AuthorRow';

const withStore = connect((state) => ({
  users: state.Users.list,
  assignments: state.Shared.assignments && state.Shared.assignments.list
    ? state.Shared.assignments.list.filter((a) => (a.site ? a.site._id === state.Shared.currentSite : false))
    : [],
}));

const Wrapper = (C) => withStore(C);

const Team = ({ assignments, users }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={assignments || []}
      renderItem={(assignment) => (
        !users.find((u) => u._id === assignment.author)
          ? <div />
          : <AuthorRow user={users.find((u) => u._id === assignment.author)} />
      )}
    />
  );
};

Team.propTypes = {
  users: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
};

export default Wrapper(Team);
