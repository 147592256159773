import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { ASSETS_ENDPOINT } from '../common/config';

const UserAvatar = ({ user }) => {
  if (!user.avatarUrl) {
    return (
      <Avatar style={{ margin: 'auto', backgroundColor: '#8954BF' }}>
        {user.user.substring(0, 2).toUpperCase()}
      </Avatar>
    );
  }

  return (
    <Avatar src={`${ASSETS_ENDPOINT}/${user.avatarUrl}`} />
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserAvatar;
