import React, { Component } from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

export default class JSONEditorReact extends Component {
  componentDidMount() {
    const options = Object.assign({}, this.props);
    delete options.json;
    delete options.text;

    this.jsoneditor = new JSONEditor(this.container, options);

    if ('json' in this.props) {
      this.jsoneditor.set(this.props.json);
    }
  }

  componentDidUpdate() {
    if ('json' in this.props) {
      this.jsoneditor.update(this.props.json);
    }


    if ('mode' in this.props) {
      this.jsoneditor.setMode(this.props.mode);
    }
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  render() {
    return <div className="jsoneditor-react-container" ref={(elem) => (this.container = elem)} />;
  }
}
