// import { debug } from './logger';

import { setupStore } from '../store/index';

export { RELEASE_VERSION, RELEASE_CODENAME, RELEASE_DATE } from './release';
const serializedState = localStorage.getItem('redux');
const shared = serializedState && JSON.parse(serializedState).Shared;
const store = setupStore();
console.log('store', store.getState().Shared);
let api = 'https://dev.backend.iris.emiketic.com';
// const shared = store.getState().Shared;

if (shared && shared.language === 'en') {
  api = 'https://www.dev.backend.iris.en.emkt.dev';
}

export const API_ENDPOINT = 'https://dev.backend.iris.emkt.dev';
// process.env.REACT_APP_API_ENDPOINT
//   || api;
// 'http://dev.backend.iris.emkt.dev';
// 'http://dev.backend.iris.emiketic.com';

export const ASSETS_ENDPOINT = `${API_ENDPOINT}/storage/uploads`;
export const API_TOKEN = '74f9c7d83e4012605f2086fcd3e993';
